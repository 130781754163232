import { useEffect } from "react";
import { useSelector } from "react-redux";
import "antd/dist/antd.less";

import "../styles/style.css";
import Routes from "./Routes";
import {COLORS} from "./theme";

const App = () => {
  const theme = useSelector((state) => state.theme.theme);

  useEffect(() => {
    Object.keys(COLORS).forEach((key) => {
      document.body.style.setProperty(`--${key}`, COLORS[key]);
      console.log(COLORS[key])
    });
  }, [theme]);

  return <Routes />;
};

export default App;
