import React, { useState } from "react";
import { Select, Typography } from "antd";
import { RiArrowDownSFill } from "react-icons/ri";
import { HiDocumentText } from "react-icons/hi";
import { FaMicrophoneAlt, FaTicketAlt } from "react-icons/fa";
import { MdBook } from "react-icons/md";
import "./Navbar.css";
import FireLogo from "../../assets/FireUp-removebg-preview.png";
import FireLogoOne from "../../assets/fireup-with-bg-remove.png";
import FireLogoBlack from "../../assets/FireUp-black-removebg.png";
import Navdrop from "./navdropdown/Navdrop";

const { Text } = Typography;
const Navbar = () => {
  return (
    <div className="nav-container">
      <div className="nav-sec-1">
        <div className="logo">
          <img src={FireLogoBlack} alt="site-logo" />
        </div>
        <div className="menu">
          <ul>
            <li>Product</li>
            <li className="btn">
              <Navdrop width={"55rem"} height={"15rem"} leftPosition={-120}>
                <div className="product-hover-class">
                  <div
                    className="drop-hover-class sec-1"
                    style={{ borderRight: "0.005em solid #ef5a29" }}
                  >
                    <Text className="drop-sec-title">TEAMS</Text>
                    <ul>
                      <li>For Brands</li>
                      <li>For Ecommerce</li>
                      <li>For CMOs</li>
                      <li>For Agencies</li>
                    </ul>
                  </div>
                  <div className="drop-hover-class sec-2">
                    <Text className="drop-sec-title">USE CASES</Text>
                    <div className="list-container">
                      <ul>
                        <li>Organic Campaigns</li>
                        <li>Paid Campaigns</li>
                        <li>Affiliate Programs and Social Commerce</li>
                        <li>Influencer Discovery and Vetting</li>
                        <li>Coordination and Visibility</li>
                      </ul>
                      <ul>
                        <li>Organic Campaigns</li>
                        <li>Paid Campaigns</li>
                        <li>Affiliate Programs and Social Commerce</li>
                        <li>Influencer Discovery and Vetting</li>
                        <li>Coordination and Visibility</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </Navdrop>
              Solutions
              <RiArrowDownSFill />
            </li>
            <li>Customers</li>
            <li className="btn">
              <Navdrop width={"60rem"} height={"11rem"} leftPosition={-400}>
                <div className="learn-hover-class">
                  <div
                    className="drop-hover-class"
                    style={{ borderRight: "0.005em solid #ef5a29" }}
                  >
                    <FaMicrophoneAlt
                      // style={{ color: "#456682", fontSize: "20px" }}
                      className="drop-sec-icon"
                    />
                    <Text
                      className="drop-sec-title"
                      // style={{ color: "#456682", fontWeight: 500 }}
                    >
                      Podcast
                    </Text>
                    <Text
                      className="drop-sec-text"
                      style={{ fontSize: "13px" }}
                    >
                      Listen to The Fast Traack to accelerate your influencer
                      marketing knowledge.
                    </Text>
                  </div>
                  <div
                    className="drop-hover-class"
                    style={{ borderRight: "0.005em solid #ef5a29" }}
                  >
                    <MdBook
                      // style={{ color: "#456682", fontSize: "20px" }}
                      className="drop-sec-icon"
                    />
                    <Text
                      className="drop-sec-title"
                      // style={{ color: "#456682", fontWeight: 500 }}
                    >
                      Resources
                    </Text>
                    <Text
                      className="drop-sec-text"
                      style={{ fontSize: "13px" }}
                    >
                      Case studies, insight reports and playbooks on successful
                      influencer strategies.
                    </Text>
                  </div>
                  <div
                    className="drop-hover-class"
                    style={{ borderRight: "0.005em solid #ef5a29" }}
                  >
                    <HiDocumentText
                      // style={{ color: "#456682", fontSize: "20px" }}
                      className="drop-sec-icon"
                    />
                    <Text
                      className="drop-sec-title"
                      // style={{ color: "#456682", fontWeight: 500 }}
                    >
                      Blog
                    </Text>
                    <Text
                      className="drop-sec-text"
                      style={{ fontSize: "13px" }}
                    >
                      Read the latest product news, trend analysis and editorial
                      rifts on the blog.
                    </Text>
                  </div>
                  <div className="drop-hover-class">
                    <FaTicketAlt
                      // style={{ color: "#456682", fontSize: "20px" }}
                      className="drop-sec-icon"
                    />
                    <Text
                      className="drop-sec-title"
                      // style={{ color: "#456682", fontWeight: 500 }}
                    >
                      Events
                    </Text>
                    <Text
                      className="drop-sec-text"
                      style={{ fontSize: "13px" }}
                    >
                      Come join us for exclusive events with industry experts!
                    </Text>
                  </div>
                </div>
              </Navdrop>
              Learn
              <RiArrowDownSFill />
            </li>
            {/* <li>Beauty leaderboard</li> */}
          </ul>
        </div>
      </div>
      <div className="nav-sec-2">
        <a className="log-btn" href="https://www.admin.fireupstar.com" target="blank">
          Login
        </a>
        <Text className="talk-to-us">Talk to Us</Text>
      </div>
    </div>
  );
};

export default Navbar;
