import React from "react";
import { Col, Row } from "antd";

import Layout from "../layout/Layout";

import { AiOutlineCheck } from "react-icons/ai";

function PrivacyPolicy() {
  return (
    <Layout>
      <div className="privacyPolicy">
        <Row>
          <Col xl={7} lg={7}></Col>
          <Col xl={17} lg={17}>
            <div className="tracker">
              <h1>FIREUP, INC. – Website and Platform Privacy Notice</h1>
              <p>
                FireUp, Inc. (along with each of FireUp, Inc.'s Corporate
                Affiliates (as defined below), collectively, "FireUp", “we”,
                “us” or “our”) respects your privacy and is committed to
                protecting your Personal Data. Notwithstanding the foregoing, to
                the extent any provision of this privacy notice applies to
                transfer data (as defined below), references to "FireUp", “we”,
                “us” or “our" shall refer solely to FireUp, Inc. (and not to
                any of FireUp's Corporate Affiliates).
              </p>
              <p>
                As of the Effective Date, FireUp, Inc.'s Corporate Affiliates
                include:
              </p>
              <p>
                1. FireUp UK Limited 39 Berwyn Rd, Richmond, Surrey TW105BU
              </p>
              <p>2.FireUp FR Sarl 4 rue Jules Lefebvre 75009 Paris</p>
            </div>
          </Col>
          <Col xl={7} lg={7} md={24} sm={24} xs={24}>
            <h1 className="heading">Background</h1>
          </Col>
          <Col xl={17} lg={17}>
            <div className="privacynotice">
              <p>
                This privacy notice sets out the basis on which any Personal
                Data we collect from or about you, or that you provide to us,
                including through www.FireUp.com and any sub-domain thereof
                (“our website”), will be processed by us in accordance with
                applicable Data Protection Laws, provided that this privacy
                notice does not apply to information processed under FireUp's
                Influencer Privacy Notice. In particular, if you are an
                influencer, FireUp’s Influencer Privacy Notice, available at
                https://www.FireUp.com/infl-privacy-notice, will apply to you
                in your capacity as an influencer and this privacy notice will
                apply to you in your capacity as a general user of our website
                and/or the Platform. Please read the following carefully to
                understand our views and practices regarding your Personal Data
                and how we will treat it.
              </p>
              <p>
                Our website is not intended for children and we do not knowingly
                collect data relating to children.
              </p>
              <p>
                It is important that you read this privacy notice together with
                any other privacy notice or fair processing notice we may
                provide on specific occasions when we are collecting or
                processing personal data about you so that you are fully aware
                of how and why we are using your data. This privacy notice
                supplements the other notices and is not intended to override
                them.
              </p>
              <p>
                As described in further detail below, FireUp, Inc. complies
                with the EU-U.S. Privacy Shield Framework and the Swiss-U.S.
                Privacy Shield Framework as set forth by the U.S. Department of
                Commerce, and the associated Privacy Shield Principles
                (currently available at www.privacyshield.gov), regarding the
                collection, use, and retention of Personal Data (as defined
                below) transferred from the European Economic Area (the “EEA”),
                the United Kingdom and/or Switzerland, respectively, to the
                United States (“transfer data”). The Privacy Shield List is
                available at https://www.privacyshield.gov/list. If there is any
                conflict between the terms in this privacy notice and the
                Privacy Shield Principles, the Privacy Shield Principles shall
                prevail. Although FireUp remains self-certified to the Privacy
                Shield, FireUp no longer relies on the Privacy Shield as its
                data transfer mechanism. Please review this privacy notice for
                additional details regarding FireUp’s participation in the
                Privacy Shield as well as additional information regarding
                transfers of personal data.
              </p>
            </div>
          </Col>
          <Col xl={7} lg={7} md={24} sm={24} xs={24}>
            <h1 className="heading">Platform Users</h1>
          </Col>
          <Col xl={17} lg={17}>
            <p className="associated">
              If an organization with which you are associated (an
              “Organization”) signs up to use our Platform, we may receive
              Personal Data about you in connection with our provision of the
              Platform to your Organization. To the extent we process that
              Personal Data solely in order to provide the Platform to your
              Organization, under the European Data Protection Laws, to the
              extent applicable, we will act as a processor (as defined in the
              European Data Protection Laws) on behalf of your Organization in
              respect of that Personal Data; this privacy notice will not apply
              to the processing of that Personal Data and your Organization will
              act as a controller (as defined in the European Data Protection
              Laws) in respect of that Personal Data and is responsible for
              obtaining all necessary consents and providing you with all
              requisite information as required by Applicable Law. To the extent
              we process your Personal Data for any other lawful business
              purpose of ours, under the European Data Protection Laws, to the
              extent applicable, we will act as a controller of such Personal
              Data and this privacy notice will apply to the processing of such
              Personal Data. For clarification but not limitation, information
              we process for our legitimate business purposes, such as product
              development, sales and marketing, is not processed solely on
              behalf of your Organization. Notwithstanding the foregoing, and
              for the avoidance of doubt, with respect to transfer data
              processed by FireUp, Inc. solely on behalf of a third-party
              controller (“Processed Transfer Data”), the provisions of this
              privacy notice specific to transfer data continue to apply in
              accordance with the EU-U.S. and/or Swiss-U.S. Privacy Shield
              Frameworks (as the case may be), but may be limited to working
              with the respective controller, given our role as a processor.
            </p>
          </Col>
          <Col xl={7} lg={7} md={24} sm={24} xs={24}>
            <h1 className="heading">Terms</h1>
          </Col>
          <Col xl={17} lg={17}>
            <div className="organization">
              <h1>‍1. DEFINITIONS‍</h1>
              <p>
                In this privacy notice the following words and phrases shall
                have the following meanings:
              </p>
              <p>
                “Applicable Law” means (a) any law, statute, regulation, bylaw
                or subordinate legislation in force from time to time to which
                we are subject; (b) the common law and laws of equity as
                applicable to us from time to time; (c) any court order,
                judgment or decree which is binding on us; (d) any industry
                code, policy or standard which is applicable to us; (e) any
                applicable direction, policy, rule or order that is binding on
                us and that is made or given by any regulatory body having
                jurisdiction over us;
              </p>
              <p>
                “Data Protection Laws” means any Applicable Law relating to the
                processing, privacy, and use of Personal Data, including but not
                restricted to (a) the General Data Protection Regulation (EU)
                2016/679 ("GDPR"); (b) the UK Data Protection Laws; and (c) any
                judicial or administrative interpretation of any such Applicable
                Law, and any guidance, guidelines, codes of practice, approved
                codes of conduct or approved certification mechanisms issued by
                any relevant regulatory or supervisory authority;
              </p>
              <p>
                “European Data Protection Laws” means the GDPR and/or UK Data
                Protection Laws, in each case to the extent applicable.
              </p>
              <p>
                “Data” means information which is stored electronically, on a
                computer, or in certain paper-based filing systems;
              </p>
              <p>
                “Personal Data” means any information relating to an identified
                or identifiable natural person (a “Data Subject”); an
                identifiable natural person is one who can be identified,
                directly or indirectly, in particular by reference to an
                identifier such as a name, an identification number, location
                data, an online identifier or to one or more factors specific to
                the physical, physiological, genetic, mental, economic, cultural
                or social identity of that natural person and where referred to
                in this notice includes special categories of Personal Data; and
              </p>
              <p>
                “Platform” ‍means our Influencer Relationship Management
                Platform, including the FireUp Studio service.
              </p>
              <p>
                “UK Data Protection Laws” means data protection laws enacted
                under the Data Protection, Privacy and Electronic Communications
                (Amendments etc.) (EU Exit) Regulations 2019 (“UK GDPR”) and the
                UK Data Protection Act 2018 (“UK DPA 2018”)
              </p>
              <p>“UK” means the United Kingdom.</p>
              <h1>2. WHO WE ARE</h1>
              <p>
                FireUp is based in the United States of America. Our main
                office is at 25 Dorchester Avenue, 52497, Boston, MA 02205,
                United States of America. We have developed and manage our
                market-research database and our Platform, which allow users to
                manage, expand, validate and scale their networks with
                influencers.
              </p>
              <h1>3. INFORMATION WE COLLECT FROM YOU</h1>
              <p>We will collect and process the following Data about you:</p>
              <h3>3.1 Information you give us.</h3>
              <p>
                This is information about you that you give us through the use
                of the website and/or Platform (e.g. when you correspond with us
                by telephone, e-mail or otherwise, including when you report a
                problem with our website). The information you give us may
                include your name, address, e-mail address, online identifier,
                and social media username (or ‘handle’).
              </p>
              <p>
                ‍3.1.1 Information you may choose to provide us access to
                through use of the Platform. Notwithstanding the foregoing or
                anything else in this privacy policy, if you choose to provide
                us access to your Google data, our use of that data will be
                subject to these additional data processing restrictions
                (“Limited Uses”): ‍‍‍
              </p>
              <ul>
                <li>
                  <AiOutlineCheck
                    color="#ff291c"
                    style={{ marginRight: "10px" }}
                  />
                  3.1.1.1 We will only use access to read, write, modify or
                  control Gmail message bodies (including attachments),
                  metadata, headers, and settings to provide a web email client
                  that allows users to compose, send, read, and process emails
                  and will not transfer this Gmail data to others unless doing
                  so is necessary to provide and improve these features, comply
                  with Applicable Law, or as part of a merger, acquisition, or
                  sale of assets. ‍
                </li>
                <li>
                  <AiOutlineCheck
                    color="#ff291c"
                    style={{ marginRight: "10px" }}
                  />
                  3.1.1.2 We will not use this Google data for serving
                  advertisements.‍
                </li>
                <li>
                  <AiOutlineCheck
                    color="#ff291c"
                    style={{ marginRight: "10px" }}
                  />
                  3.1.1.3 We will not allow humans to read this data unless we
                  have your affirmative agreement for specific messages, doing
                  so is necessary for security purposes such as investigating
                  abuse, to comply with Applicable Law, or for the Platform’s
                  internal operations when the data has been aggregated and
                  anonymized.
                </li>
              </ul>
              <h3>3.2 Information we collect about you.</h3>
              <p>
                With regard to each of your visits to our website and/or
                Platform and/or in connection with your interaction with
                Platform ads on third-party sites, we will automatically
                collect, or will collect through a third party acting on our
                behalf, the following information:
              </p>
              <ul>
                <li>
                  <AiOutlineCheck
                    color="#ff291c"
                    style={{ marginRight: "10px" }}
                  />
                  ‍3.2.1 technical information, including the Internet protocol
                  (IP) address used to connect your computer to the Internet,
                  your login information, browser type and version, time zone
                  setting, browser plug-in types and versions, operating system
                  and your platform.
                </li>
                <li>
                  <AiOutlineCheck
                    color="#ff291c"
                    style={{ marginRight: "10px" }}
                  />
                  ‍3.2.2 information about your visit, including the full
                  Uniform Resource Locators (URL), clickstream to, through and
                  from our website (including date and time), page response
                  times, download errors, length of visits to certain pages,
                  page interaction information (such as scrolling, clicks, and
                  mouse-overs), and any phone number used to call our customer
                  service number.
                </li>
              </ul>
              <p>
                We may connect the above automatically collected information to
                information that a user may provide, such as information
                provided in a form that a user completes.
              </p>
              <h3>3.3 Information we collect from other sources.</h3>
              <p>
                We are working closely with, and receive Personal Data from
                third parties (such as social media platforms (such as LinkedIn)
                if you click on a link to our website from that social media
                platform, business partners, sub-contractors in technical,
                payment and delivery services, advertising networks, analytics
                providers, search information providers and credit reference
                agencies). For further details about these other sources please
                contact us using the details provided below.
              </p>
              <h1>4. COOKIES</h1>
              <p>
                Our website uses cookies to distinguish you from other users of
                our website. This helps us to provide you with a good experience
                when you browse our website and also allows us to improve our
                website. For detailed information on the cookies we use and the
                purposes for which we use them see our Cookies Policy.
              </p>
              <h1>5. LEGAL BASIS FOR PROCESSING</h1>
              <p>
                We will only use your Personal Data to the extent that the law
                allows us to do so. Most commonly, we will use your Personal
                Data in the following circumstances:
              </p>
              <ul>
                <li>
                  <AiOutlineCheck
                    color="#ff291c"
                    style={{ marginRight: "10px" }}
                  />
                  where processing is necessary for the performance of a
                  contract to which you are subject or in order to take steps at
                  your request prior to entering into a contract;
                </li>
                <li>
                  <AiOutlineCheck
                    color="#ff291c"
                    style={{ marginRight: "10px" }}
                  />
                  where it is necessary for the purposes of our legitimate
                  interests (or those of a third party) and your interests and
                  fundamental rights do not override those interests. Our
                  legitimate interests are to provide our website to you as a
                  part of the service provided to your organization;
                </li>
                <li>
                  <AiOutlineCheck
                    color="#ff291c"
                    style={{ marginRight: "10px" }}
                  />
                  where you have given consent to the processing, which consent
                  may be withdrawn at any time without affecting the lawfulness
                  of processing based on consent prior to withdrawal; and/or
                </li>
                <li>
                  <AiOutlineCheck
                    color="#ff291c"
                    style={{ marginRight: "10px" }}
                  />
                  where we need to comply with a legal or regulatory obligation.
                </li>
              </ul>
              <h1>6. USES MADE OF THE INFORMATION</h1>
              <p>We use information held about you as set out below:</p>
              <h3>6.1 Information you give to us.</h3>
              <p>We will use this information:</p>
              <ul>
                <li>
                  <AiOutlineCheck
                    color="#ff291c"
                    style={{ marginRight: "10px" }}
                  />
                  ‍6.1.1 when you subscribe to our Platform: to manage your
                  account, including to communicate with you regarding your
                  account;
                </li>
                <li>
                  <AiOutlineCheck
                    color="#ff291c"
                    style={{ marginRight: "10px" }}
                  />
                  ‍6.1.2 to operate, maintain, market and improve the Platform;
                </li>
                <li>
                  <AiOutlineCheck
                    color="#ff291c"
                    style={{ marginRight: "10px" }}
                  />
                  6.1.3 to respond to your comments and questions and to provide
                  customer service and support (including with respect to the
                  Platform); and/or
                </li>
                <li>
                  <AiOutlineCheck
                    color="#ff291c"
                    style={{ marginRight: "10px" }}
                  />
                  6.1.4 when you make an enquiry about our services or request
                  marketing materials to be sent to you.
                </li>
              </ul>
              <h3>6.2 Information we collect about you.</h3>
              <p>We will use this information:</p>
              <ul>
                <li>
                  <AiOutlineCheck
                    color="#ff291c"
                    style={{ marginRight: "10px" }}
                  />
                  ‍6.2.1 to administer our website and for internal operations,
                  including troubleshooting, data analysis, testing, research,
                  statistical and survey purposes;
                </li>
                <li>
                  <AiOutlineCheck
                    color="#ff291c"
                    style={{ marginRight: "10px" }}
                  />
                  ‍6.2.2 to improve our website to ensure that content is
                  presented in the most effective manner for you and for your
                  computer or device and to market and improve our Platform; and
                </li>
                <li>
                  <AiOutlineCheck
                    color="#ff291c"
                    style={{ marginRight: "10px" }}
                  />
                  ‍6.2.3 as part of our efforts to keep our website safe and
                  secure.
                </li>
              </ul>
              <h3>6.3 Information we receive from other sources.</h3>
              <p>
                We will combine this information with information you give to us
                and information we collect about you. We will use this
                information and the combined information for the purposes set
                out above (depending on the types of information we receive).
              </p>
              <h1>7. DISCLOSURE OF YOUR INFORMATION</h1>
              <p>
                7.1 You agree that we have the right to share your Personal Data
                with selected third parties including business partners,
                suppliers and sub-contractors in connection with our business,
                including without limitation to allow us to provide access to
                our website. Without limitation of the foregoing, we may share
                your Personal Data with professional advisers including lawyers,
                bankers, auditors and insurers who provide consultancy, banking,
                legal, insurance and accounting services.
              </p>
              <p>
                7.2 In addition to the above, we may disclose your Personal Data
                to third parties in the following ways:
              </p>
              <ul>
                <li>
                  <AiOutlineCheck
                    color="#ff291c"
                    style={{ marginRight: "10px" }}
                  />
                  7.2.1 in the event that we sell or buy any business or assets,
                  in which case we may disclose your Personal Data to the
                  prospective seller or buyer of such business or assets and in
                  connection with any such transaction;
                </li>
                <li>
                  <AiOutlineCheck
                    color="#ff291c"
                    style={{ marginRight: "10px" }}
                  />
                  7.2.2 if FireUp or substantially all of its assets is or are
                  acquired by a third party, in which case Personal Data held by
                  us about our customers will be one of the transferred assets
                  and we may disclose your Personal Data in connection with any
                  such transaction;
                </li>
                <li>
                  <AiOutlineCheck
                    color="#ff291c"
                    style={{ marginRight: "10px" }}
                  />
                  7.2.3 if we discontinue our business, or file a petition or
                  have filed against us a petition in bankruptcy, reorganization
                  or similar insolvency petition, application or proceeding we
                  may disclose your Personal Data to persons dealing with such
                  discontinuation or in connection with any such petition,
                  application or proceeding;
                </li>
                <li>
                  <AiOutlineCheck
                    color="#ff291c"
                    style={{ marginRight: "10px" }}
                  />
                  7.2.4 we may disclose your Personal Data to our Corporate
                  Affiliates; for the purposes of this privacy notice:
                  "Corporate Affiliate" means any person or entity which
                  directly or indirectly controls, is controlled by or is under
                  common control with FireUp, Inc., whether by ownership or
                  otherwise; and “control” means possessing, directly or
                  indirectly, the power to direct or cause the direction of the
                  management, policies or operations of an entity, whether
                  through ownership of fifty percent (50%) or more of the voting
                  securities, by contract or otherwise;
                </li>
                <li>
                  <AiOutlineCheck
                    color="#ff291c"
                    style={{ marginRight: "10px" }}
                  />
                  7.2.5 we may disclose your Personal Data to any third party to
                  whom we are under a duty to disclose or share your Personal
                  Data in order to comply with any legal obligation, including
                  without limitation public authorities to meet national
                  security or law enforcement requirements, and regulators and
                  other authorities who require reporting of processing
                  activities in certain circumstances;
                </li>
                <li>
                  <AiOutlineCheck
                    color="#ff291c"
                    style={{ marginRight: "10px" }}
                  />
                  7.2.6 we may disclose your Personal Data to third parties with
                  whom we may be required to communicate in order to enforce or
                  apply our agreements with your organization and other
                  agreements; or to protect the rights, property, or safety of
                  FireUp, our customers, or others; and
                </li>
                <li>
                  <AiOutlineCheck
                    color="#ff291c"
                    style={{ marginRight: "10px" }}
                  />
                  7.2.7 where necessary, we may disclose your Personal Data to
                  analytics and search engine providers that assist us in the
                  improvement and optimisation of our website.
                </li>
              </ul>
              <p>
                In addition to the above, third parties may directly collect
                personally identifiable and non-personally identifiable
                information about our users’ online activities over time and
                across different websites.
              </p>
              <h1>8. WHERE WE STORE YOUR PERSONAL DATA</h1>
              <p>
                8.1 Unfortunately, the transmission of information via the
                Internet is not completely secure. Although we will do our best
                to protect your Personal Data, we cannot guarantee the security
                of your Data transmitted through out website; any transmission
                is at your own risk. Once we have received your information, we
                will use strict procedures and security features to try to
                prevent unauthorised access.
              </p>
              <p>
                8.2 Our external third-party providers may be based outside the
                European Economic Area (EEA) or UK (as applicable) so their
                processing of your Personal Data will involve a transfer of Data
                outside the EEA or UK (as applicable).
              </p>
              <p>
                8.3 Whenever we transfer your Personal Data out of the EEA or UK
                (as applicable), we ensure a similar degree of protection is
                afforded to it. Where we use certain service providers, we may
                use specific contracts approved by the European Commission or
                the UK Information Commissioner’s Office, as applicable, which
                give Personal Data the same protection it has in the EEA or UK
                (as applicable) under the European Data Protection Laws. If we
                rely on another basis to transfer your Personal Data outside of
                the EEA or UK (as applicable) (such as your consent), we will
                keep you updated or contact you as required.
              </p>
              <p>
                8.4 Please contact us if you want further information on the
                specific mechanisms used by us when transferring your Personal
                Data out of the EEA or UK (as applicable).
              </p>
              <h1>9. THIRD PARTY LINKS</h1>
              <p>
                Our website, from time to time, may contain links to and from
                third-party websites including the websites of our partner
                networks, advertisers and affiliates. This may include providing
                you with the ability to automatically post updates on social
                media. If you follow a link to any of these websites, please
                note that these websites have their own privacy policies and
                that we do not accept any responsibility or liability for these
                policies. Please check these policies before you submit any
                Personal Data to these websites.
              </p>
              <h1>10. DATA SECURITY</h1>
              <p>
                10.1 We have put in place appropriate security measures to
                prevent your Personal Data from being accidentally lost, used or
                accessed in an unauthorised way, altered or disclosed. In
                addition, we limit access to your Personal Data to those
                employees, agents, contractors and other third parties who have
                a business need to know. They will only process your Personal
                Data on our instructions, and they are subject to a duty of
                confidentiality.
              </p>
              <p>
                10.2 We have put in place procedures to deal with any suspected
                Personal Data breach and will notify you and any applicable
                regulator of a breach where we are legally required to do so.
              </p>
              <h1>11. DATA RETENTION</h1>
              <p>
                11.1 We will only retain your Personal Data for as long as
                necessary to fulfill the purposes we collected it for, including
                for the purposes of satisfying any legal, accounting, or
                reporting requirements.
              </p>
              <p>
                11.2 To determine the appropriate retention period for Personal
                Data, we consider the amount, nature, and sensitivity of the
                Personal Data, the potential risk of harm from unauthorised use
                or disclosure of your Personal Data, the purposes for which we
                process your Personal Data and whether we can achieve those
                purposes through other means, and the applicable legal
                requirements.
              </p>
              <h1>12. YOUR RIGHTS</h1>
              <p>
                Under certain circumstances and in compliance with the European
                Data Protection Laws, you may have the right to:
              </p>
              <ul>
                <li>
                  <AiOutlineCheck
                    color="#ff291c"
                    style={{ marginRight: "10px" }}
                  />
                  ‍12.1 Request access to your Personal Data (commonly known as
                  a ‘data subject access request’). This enables you to receive
                  a copy of the Personal Data we hold about you and to check
                  that we are lawfully processing it. You will usually not have
                  to pay a fee to access your Personal Data (or to exercise any
                  of the other rights). However, we may charge a reasonable fee
                  if your request is clearly unfounded, repetitive or excessive.
                  Alternatively, we may refuse to comply with your request in
                  these circumstances.
                </li>
                <li>
                  <AiOutlineCheck
                    color="#ff291c"
                    style={{ marginRight: "10px" }}
                  />
                  ‍12.2 Request correction of the Personal Data that we hold
                  about you. This enables you to have any incomplete or
                  inaccurate Personal Data we hold about you corrected, though
                  we may need to verify the accuracy of the new Personal Data
                  you provide to us.
                </li>
                <li>
                  <AiOutlineCheck
                    color="#ff291c"
                    style={{ marginRight: "10px" }}
                  />
                  ‍12.3 Request erasure of your Personal Data. This enables you
                  to ask us to delete or remove Personal Data where there is no
                  good reason for us continuing to process it. You also have the
                  right to ask us to delete or remove your Personal Data where
                  you have successfully exercised your right to object to
                  processing (see below), where we may have processed your
                  information unlawfully or where we are required to erase your
                  Personal Data to comply with local law. Note, however, that we
                  may not always be able to comply with your request of erasure
                  for specific legal reasons which will be notified to you, if
                  applicable, at the time of your request.
                </li>
                <li>
                  <AiOutlineCheck
                    color="#ff291c"
                    style={{ marginRight: "10px" }}
                  />
                  ‍12.4 Object to processing of your Personal Data where we are
                  relying on a legitimate interest (or those of a third party)
                  and there is something about your particular situation which
                  makes you want to object to processing on this ground as you
                  feel it impacts on your fundamental rights and freedoms. You
                  also have the right to object where we are processing your
                  Personal Data for direct marketing purposes. In some cases, we
                  may demonstrate that we have compelling legitimate grounds to
                  process your information which override your rights and
                  freedoms.
                </li>
                <li>
                  <AiOutlineCheck
                    color="#ff291c"
                    style={{ marginRight: "10px" }}
                  />
                  ‍12.5 Request restriction of processing of your Personal Data.
                  This enables you to ask us to suspend the processing of your
                  Personal Data in the following scenarios: (a) if you want us
                  to establish the Personal Data’s accuracy; (b) where our use
                  of the Personal Data is unlawful but you do not want us to
                  erase it; (c) where you need us to hold the Personal Data even
                  if we no longer require it as you need it to establish,
                  exercise or defend legal claims; or (d) you have objected to
                  our use of your Personal Data but we need to verify whether we
                  have overriding legitimate grounds to use it.
                </li>
                <li>
                  <AiOutlineCheck
                    color="#ff291c"
                    style={{ marginRight: "10px" }}
                  />
                  ‍12.6 Request the transfer of your Personal Data to you or to
                  a third party. We will provide to you, or a third party you
                  have chosen, your Personal Data in a structured, commonly
                  used, machine-readable format. Note that this right only
                  applies to automated information which you initially provided
                  consent for us to use or where we used the information to
                  perform a contract with you.
                </li>
              </ul>
              <h1>13. HOW TO EXERCISE YOUR RIGHTS</h1>
              <p>
                In order to exercise any of your rights please contact us using
                the contact details provided in section 21 of this notice.
              </p>
              <h1>14. WHAT WE MAY NEED FROM YOU</h1>
              <p>
                We may need to request specific information from you to help us
                confirm your identity and ensure your right to access your
                Personal Data (or to exercise any of your other rights). This is
                a security measure to ensure that Personal Data is not disclosed
                to any person who has no right to receive it. We may also
                contact you to ask you for further information in relation to
                your request to speed up our response.
              </p>
              <h1>15. TIME LIMIT TO RESPOND</h1>
              <p>
                We try to respond to all legitimate requests within one month.
                Occasionally it may take us longer than a month if your request
                is particularly complex or you have made a number of requests.
                In this case, we will notify you and keep you updated.
              </p>
              <h1>
                16. RIGHT TO LODGE A COMPLAINT WITH A SUPERVISORY AUTHORITY
              </h1>
              <p>
                If you have any complaints about the way in which we process
                your Personal Data please do contact us, as set out below in
                Section 21. Alternatively, under certain circumstances and in
                compliance with European Data Protection Laws, you have the
                right to lodge a formal complaint with your data protection
                supervisory authority.
              </p>
              <h1>17. CHANGE OF PURPOSE</h1>
              <p>
                17.1 We will only use your Personal Data for the purposes for
                which we collected it, unless we reasonably consider that we
                need to use it for another reason and that reason is compatible
                with the original purpose. If you wish to get an explanation as
                to how the processing for the new purpose is compatible with the
                original purpose, please contact us in the manner described in
                Section 21.
              </p>
              <p>
                17.2 If we need to use your Personal Data for an unrelated
                purpose, we will notify you and we will explain the legal basis
                which allows us to do so.
              </p>
              <p>
                17.3 Please note that we may process your Personal Data without
                your knowledge or consent, in compliance with the above rules,
                where this is required or permitted by law.
              </p>
              <h1>18. DO NOT TRACK</h1>
              <p>
                The term “Do Not Track” refers to a HTTP header offered by
                certain web browsers to request that websites refrain from
                tracking the user. We take no action in response to Do Not Track
                requests. However, if you wish to stop such tracking, please
                contact us with your request, using our contact details provided
                in Section 21 below.
              </p>
              <h1>19. CHANGES TO OUR PRIVACY NOTICE</h1>
              <p>
                Any changes we make to our privacy notice in the future will be
                posted to this section of our website and, where appropriate,
                notified to you by e-mail. We will not make changes that result
                in significant additional uses or disclosures of your personally
                identifiable information without allowing you to ‘opt in’ to
                such changes. We may also make non-significant changes to this
                privacy notice that generally will not significantly affect our
                use of your personally identifiable information, for which your
                opt-in is not required. Please check back frequently to see any
                updates or changes to our privacy notice.
              </p>
              <h1>20. PRIVACY NOTICE FOR CALIFORNIA</h1>
              <p>Residents</p>
              <p>
                This Section 20 shall apply to you only if you are a California
                resident. This Section 20 shall apply only to the extent FireUp
                is regulated under the CCPA (as defined below) as a business (as
                defined in the CCPA).
              </p>
              <p>
                If an Organization with which you are associated signs up to use
                our Platform, we may receive consumer information (as defined
                below) about you in connection with our provision of the
                Platform to your Organization. To the extent we process such
                consumer information solely in order to provide the Platform to
                your Organization, under the CCPA, to the extent applicable, we
                will act as a service provider (as defined in the CCPA) on
                behalf of your Organization in respect of that consumer
                information; this privacy notice will not apply to the
                processing of that consumer information and your Organization
                will act as a business (as defined in the CCPA) in respect of
                that consumer information. The business is responsible for
                obtaining all necessary consents and providing you with all
                requisite information as required by Applicable Law. To the
                extent we process your consumer information for any other lawful
                business purpose of ours, under the CCPA, to the extent
                applicable, we will act as a business with respect to such
                consumer information and this privacy notice will apply to the
                processing of such consumer information. For clarification but
                not limitation, information we process for our legitimate
                business or commercial purposes, such as product development,
                sales and marketing, is not processed solely on behalf of your
                Organization.
              </p>
              <p>
                As used in this Section 20, “sell” (including any grammatically
                inflected forms thereof) means selling, renting, releasing,
                disclosing, disseminating, making available, transferring, or
                otherwise communicating orally, in writing, or by electronic or
                other means, consumer information (as defined below) to another
                business or a third party for monetary or other valuable
                consideration.
              </p>
              <p>
                “Selling” does not include (i) disclosing consumer information
                to a third party at your direction, provided the third party
                does not sell the consumer information except in accordance with
                the California Consumer Privacy Act (the “CCPA”), (ii) where you
                intentionally interact with a third party through the website,
                provided the third party does not also sell the consumer
                information, (iii) using or sharing your consumer information
                with a service provider as necessary to perform business
                purposes, provided that such service provider provides its
                services on FireUp’s behalf and provided that the service
                provider does not further collect, sell or use the consumer
                information except as necessary to perform the business purpose,
                or (iv) transfers of your consumer information to a third party
                as an asset that is part of a merger, acquisition, bankruptcy,
                or other transaction in which the third party assumes control of
                all or part of FireUp, provided that information is used or
                shared consistently with the CCPA.
              </p>
              <p>
                Where noted in this privacy notice, until the applicable date
                specified in the CCPA, the CCPA exempts consumer information
                reflecting a written or verbal business-to-business
                communication ("B2B consumer information") from some its
                requirements, provided that such exemptions shall not apply from
                and after such date specified by the CCPA.
              </p>
              <p>
                20.1 Consumer Information Collected: We collect information that
                identifies, relates to, describes, references, is capable of
                being associated with, or could reasonably be linked, directly
                or indirectly, with particular California residents, devices or
                households ("consumer information"). Consumer information does
                not include deidentified or aggregated information, publicly
                available information from government records, or any other
                information that is excepted from the definition of "personal
                information" under the CCPA, or any information that is
                otherwise not regulated by the CCPA. In particular, we have
                collected the following categories of consumer information from
                California residents, households or devices within the last
                twelve (12) months:‍‍
              </p>
              <div className="line"></div>
            </div>
          </Col>
          <Col xl={7} lg={7}></Col>
          <Col xl={5} lg={5}>
            <p>A. Identifiers. </p>
          </Col>
          <Col xl={6} lg={6}>
            <p>
              Name, online identifier, Internet Protocol address, email address
            </p>
          </Col>
          <Col xl={6} lg={6} >
            <ul>
              <li>
                <AiOutlineCheck
                  color="#ff291c"
                  style={{ marginRight: "10px" }}
                />
                - to manage your account, including to communicate with you
                regarding your account.
              </li>
              <li>
                <AiOutlineCheck
                  color="#ff291c"
                  style={{ marginRight: "10px" }}
                />
                - to operate, maintain, market and improve the Platform.
              </li>
              <li>
                <AiOutlineCheck
                  color="#ff291c"
                  style={{ marginRight: "10px" }}
                />
                - to respond to your comments and questions and to provide
                customer service and support (including with respect to the
                Platform).
              </li>
              <li>
                <AiOutlineCheck
                  color="#ff291c"
                  style={{ marginRight: "10px" }}
                />
                - when you make an enquiry about our services or request
                marketing materials to be sent to you
              </li>
              <li>
                <AiOutlineCheck
                  color="#ff291c"
                  style={{ marginRight: "10px" }}
                />
                - to administer our website and for internal operations,
                including troubleshooting, data analysis, testing, research,
                statistical and survey purposes.
              </li>
              <li>
                <AiOutlineCheck
                  color="#ff291c"
                  style={{ marginRight: "10px" }}
                />
                - to improve our website to ensure that content is presented in
                the most effective manner for you and for your computer or
                device and to market and improve our Platform.
              </li>
            </ul>
          </Col>
          <Col xl={7} lg={7}></Col>
          <Col xl={17} lg={17}>
            <div className="line"></div>
          </Col>
          <Col xl={7} lg={7}></Col>
          <Col xl={6} lg={6}>
            <p>
              B. Personal information categories listed in the California
              Customer Records statute (Cal. Civ. Code § 1798.80(e)).
            </p>
          </Col>
          <Col xl={5} lg={5}>
            <p>Name</p>
          </Col>
          <Col xl={6} lg={6} >
            <ul>
              <li>
                <AiOutlineCheck
                  color="#ff291c"
                  style={{ marginRight: "10px" }}
                />
                - to manage your account, including to communicate with you
                regarding your account.
              </li>
              <li>
                <AiOutlineCheck
                  color="#ff291c"
                  style={{ marginRight: "10px" }}
                />
                - to operate, maintain, market and improve the Platform.
              </li>
              <li>
                <AiOutlineCheck
                  color="#ff291c"
                  style={{ marginRight: "10px" }}
                />
                - to respond to your comments and questions and to provide
                customer service and support (including with respect to the
                Platform).
              </li>
              <li>
                <AiOutlineCheck
                  color="#ff291c"
                  style={{ marginRight: "10px" }}
                />
                - when you make an enquiry about our services or request
                marketing materials to be sent to you
              </li>
              <li>
                <AiOutlineCheck
                  color="#ff291c"
                  style={{ marginRight: "10px" }}
                />
                - to administer our website and for internal operations,
                including troubleshooting, data analysis, testing, research,
                statistical and survey purposes.
              </li>
              <li>
                <AiOutlineCheck
                  color="#ff291c"
                  style={{ marginRight: "10px" }}
                />
                - to improve our website to ensure that content is presented in
                the most effective manner for you and for your computer or
                device and to market and improve our Platform.
              </li>
              <li>
                <AiOutlineCheck
                  color="#ff291c"
                  style={{ marginRight: "10px" }}
                />
                - as part of our efforts to keep our website safe and secure.
              </li>
            </ul>
          </Col>
          <Col xl={7} lg={7}></Col>
          <Col xl={17} lg={17}>
            <div className="line"></div>
          </Col>
          <Col xl={7} lg={7}></Col>
          <Col xl={5} lg={5}>
            <p>C. Internet or other similar network activity.</p>
          </Col>
          <Col xl={6} lg={6}>
            <p>
              Browsing history, search history, information on a consumer's
              interaction with a website, application, or advertisement.
            </p>
          </Col>
          <Col xl={6} lg={6} >
            <ul>
              <li>
                <AiOutlineCheck
                  color="#ff291c"
                  style={{ marginRight: "10px" }}
                />
                - to manage your account, including to communicate with you
                regarding your account.
              </li>
              <li>
                <AiOutlineCheck
                  color="#ff291c"
                  style={{ marginRight: "10px" }}
                />
                - to operate, maintain, market and improve the Platform.
              </li>
              <li>
                <AiOutlineCheck
                  color="#ff291c"
                  style={{ marginRight: "10px" }}
                />
                - to respond to your comments and questions and to provide
                customer service and support (including with respect to the
                Platform).
              </li>
              <li>
                <AiOutlineCheck
                  color="#ff291c"
                  style={{ marginRight: "10px" }}
                />
                - when you make an enquiry about our services or request
                marketing materials to be sent to you
              </li>
              <li>
                <AiOutlineCheck
                  color="#ff291c"
                  style={{ marginRight: "10px" }}
                />
                - to administer our website and for internal operations,
                including troubleshooting, data analysis, testing, research,
                statistical and survey purposes.
              </li>
              <li>
                <AiOutlineCheck
                  color="#ff291c"
                  style={{ marginRight: "10px" }}
                />
                - to improve our website to ensure that content is presented in
                the most effective manner for you and for your computer or
                device and to market and improve our Platform.
              </li>
              <li>
                <AiOutlineCheck
                  color="#ff291c"
                  style={{ marginRight: "10px" }}
                />
                - as part of our efforts to keep our website safe and secure.
              </li>
            </ul>
          </Col>
          <Col xl={7} lg={7}></Col>
          <Col xl={17} lg={17}>
                  <div className="line"></div>
          </Col>
          <Col xl={7} lg={7}></Col>
          <Col xl={17} lg={17}>
            <div className="Consumer">
              <p>
                20.2 Use of Consumer Information; Categories of Sources: We use
                consumer information for the business or commercial purposes
                described in the table above and in the manner described in
                Section 6 of this privacy notice with respect to Personal Data.
                Regarding the categories of sources from which consumer
                information is collected, we collect consumer information from
                the categories of sources specified in the table above.
              </p>
              <p>
                20.3 Disclosures of Consumer Information for a Business or
                Commercial Purpose: FireUp may disclose your consumer
                information described in the table above to a third party for a
                business or commercial purpose, as described in the table above
                and in Section 7 of this privacy notice with respect to Personal
                Data. In the preceding twelve (12) months, FireUp has disclosed
                the categories of consumer information described in the table
                above for a business or commercial purpose to the categories of
                third parties described in the table above.
              </p>
              <p>
                20.4 Sales of Consumer Information: In the preceding twelve (12)
                months, FireUp has not sold, and FireUp does not and will not
                sell, consumer information that is subject to this privacy
                notice; however, please see FireUp's Influencer Privacy Notice
                for details regarding FireUp's collection and use (including
                sale) of publicly available information about influential
                individuals, including sharing of such publicly available
                information about influential individuals with FireUp's
                business customers.
              </p>
              <p>
                20.5 California Residents’ Rights and Choices: The CCPA provides
                California residents with specific rights regarding their
                consumer information. This Section 20.5 describes your CCPA
                rights (to the extent applicable to you) and explains how to
                exercise those rights.
              </p>
              <p>
                20.5.1 Access to Specific Information and Data Portability
                Rights: You may have the right to request that FireUp disclose
                certain information to you about our collection and use of your
                consumer information over the past 12 months. Once we receive
                and confirm your verifiable consumer request (in the manner
                described in Section 20.6 below), to the extent required by the
                CCPA, we will disclose to you:
              </p>
              <ul>
                <li>
                  <AiOutlineCheck
                    color="#ff291c"
                    style={{ marginRight: "10px" }}
                  />
                  20.5.1.1 The categories of consumer information we collected
                  about you.
                </li>
                <li>
                  <AiOutlineCheck
                    color="#ff291c"
                    style={{ marginRight: "10px" }}
                  />
                  20.5.1.2 The categories of sources for the consumer
                  information we collected about you.
                </li>
                <li>
                  <AiOutlineCheck
                    color="#ff291c"
                    style={{ marginRight: "10px" }}
                  />
                  20.5.1.3 Our business or commercial purpose for collecting
                  that consumer information.
                </li>
                <li>
                  <AiOutlineCheck
                    color="#ff291c"
                    style={{ marginRight: "10px" }}
                  />
                  20.5.1.4 The categories of third parties with whom we share
                  that consumer information.
                </li>
                <li>
                  <AiOutlineCheck
                    color="#ff291c"
                    style={{ marginRight: "10px" }}
                  />
                  20.5.1.5 The specific pieces of consumer information we
                  collected about you (also called a data portability request).
                </li>
                <li>
                  <AiOutlineCheck
                    color="#ff291c"
                    style={{ marginRight: "10px" }}
                  />
                  20.5.1.6 If we disclosed your consumer information for a
                  business or commercial purpose, a list disclosing disclosures
                  for a business or commercial purpose, identifying the consumer
                  information categories that each category of recipient
                  obtained.
                </li>
              </ul>
              <p>
                We will not provide the foregoing access and data portability
                rights for B2B consumer information prior to such date required
                by the CCPA.
              </p>
              <p>
                20.5.2 Deletion Request Rights: You have the right to request
                that FireUp delete any of your consumer information that we
                collected from you and retained, subject to certain exceptions.
                Once we receive and confirm a verifiable request from you (if
                you are a California resident) in the manner described in
                Section 20.6 below (“verifiable consumer request”), we will
                delete (and direct our service providers to delete) your
                consumer information from our records, unless an exception
                applies. We may deny your deletion request if retaining the
                information is necessary for us or our service provider(s) to:
              </p>
              <ul>
                <li>
                  <AiOutlineCheck
                    color="#ff291c"
                    style={{ marginRight: "10px" }}
                  />
                  20.5.2.1 Complete the transaction for which we collected the
                  consumer information, provide a product or service that you
                  requested, take actions reasonably anticipated within the
                  context of our ongoing business relationship with you, fulfill
                  the terms of a written warranty or product recall conducted in
                  accordance with federal law, or otherwise perform our contract
                  with you.
                </li>
                <li>
                  <AiOutlineCheck
                    color="#ff291c"
                    style={{ marginRight: "10px" }}
                  />
                  20.5.2.2 Detect security incidents, protect against malicious,
                  deceptive, fraudulent, or illegal activity, or prosecute those
                  responsible for such activities.
                </li>
                <li>
                  <AiOutlineCheck
                    color="#ff291c"
                    style={{ marginRight: "10px" }}
                  />
                  20.5.2.3 Debug products or services to identify and repair
                  errors that impair existing intended functionality.
                </li>
                <li>
                  <AiOutlineCheck
                    color="#ff291c"
                    style={{ marginRight: "10px" }}
                  />
                  20.5.2.4 Exercise free speech, ensure the right of another
                  consumer to exercise their free speech rights, or exercise
                  another right provided for by law.
                </li>
                <li>
                  <AiOutlineCheck
                    color="#ff291c"
                    style={{ marginRight: "10px" }}
                  />
                  20.5.2.5 Comply with the California Electronic Communications
                  Privacy Act (Cal. Penal Code § 1546 et. seq.).
                </li>
                <li>
                  <AiOutlineCheck
                    color="#ff291c"
                    style={{ marginRight: "10px" }}
                  />
                  20.5.2.6 Engage in public or peer-reviewed scientific,
                  historical, or statistical research in the public interest
                  that adheres to all other applicable ethics and privacy laws,
                  when the information's deletion may likely render impossible
                  or seriously impair the research's achievement, if you
                  previously provided informed consent.
                </li>
                <li>
                  <AiOutlineCheck
                    color="#ff291c"
                    style={{ marginRight: "10px" }}
                  />
                  20.5.2.7 Enable solely internal uses that are reasonably
                  aligned with consumer expectations based on your relationship
                  with us.
                </li>
                <li>
                  <AiOutlineCheck
                    color="#ff291c"
                    style={{ marginRight: "10px" }}
                  />
                  20.5.2.8 Comply with a legal obligation.
                </li>
                <li>
                  <AiOutlineCheck
                    color="#ff291c"
                    style={{ marginRight: "10px" }}
                  />
                  20.5.2.9 Make other internal and lawful uses of that
                  information that are compatible with the context in which you
                  provided it.
                </li>
              </ul>
              <p>
                We will not provide the foregoing deletion rights for B2B
                consumer information prior to such date required by the CCPA.
              </p>
              <p>
                20.6 Exercising Access, Data Portability, and Deletion Rights.
              </p>
              <ul>
                <li>
                  <AiOutlineCheck
                    color="#ff291c"
                    style={{ marginRight: "10px" }}
                  />
                  20.6.1 To exercise the access, data portability, and deletion
                  rights described in Section 20.5 above, please submit a
                  verifiable consumer request to us by either: (1) calling us at
                  +1(855)-FireUp; (2) visiting
                  https://www.FireUp.com/data-opt-out; or (3) contacting us in
                  accordance with Section 21. Only you, someone legally
                  authorized to act on your behalf, (such as an authorized
                  agent) may make a verifiable consumer request related to your
                  consumer information. You may also make a verifiable consumer
                  request on behalf of your minor child. You may make a
                  verifiable consumer request for access or data portability no
                  more than twice within a 12-month period. The verifiable
                  consumer request must: (i) provide sufficient information that
                  allows us to reasonably verify you are the person about whom
                  we collected consumer information or an authorized
                  representative; and (ii) describe your request with sufficient
                  detail that allows us to properly understand, evaluate, and
                  respond to it. We cannot respond to your request or provide
                  you with consumer information if we cannot verify your
                  identity or authority to make the request and confirm the
                  consumer information relates to you. Making a verifiable
                  consumer request does not require you to create an account
                  with us. We will only use consumer information provided in a
                  verifiable consumer request to verify the requestor's identity
                  or authority to make the request. In the event you make a
                  request under this paragraph, we may take various approaches
                  to verify your identity depending on the nature of your
                  request. These approaches may include asking you questions
                  pertaining to the information we have about you.
                </li>
                <li>
                  <AiOutlineCheck
                    color="#ff291c"
                    style={{ marginRight: "10px" }}
                  />
                  20.6.2 We endeavour to respond to a verifiable consumer
                  request within forty-five (45) days of its receipt. If we
                  require more time (up to ninety (90) days), we will inform you
                  of the reason and extension period in writing. If you have an
                  account with us, we may deliver our written response to that
                  account. If you do not have an account with us, we will
                  deliver our written response by mail or electronically, at
                  your option. Any disclosures we provide will only cover the
                  12-month period preceding the verifiable consumer request's
                  receipt. The response we provide will also explain the reasons
                  we cannot comply with a request, if applicable. For data
                  portability requests, we will select a format to provide your
                  consumer information that is readily useable and should allow
                  you to transmit the information from one entity to another
                  entity without hindrance. If your requests are manifestly
                  unfounded or excessive, in particular because of their
                  repetitive character, we may either charge a reasonable fee,
                  taking into account the administrative costs of providing the
                  information or communication or taking the action requested,
                  or refuse to act on the request and notify you of the reason
                  for refusing the request.
                </li>
              </ul>
              <p>
                20.7 Non-Discrimination: We will not discriminate against you
                for exercising any of your CCPA rights, including, unless
                permitted by the CCPA, by:
              </p>
              <ul>
                <li>
                  <AiOutlineCheck
                    color="#ff291c"
                    style={{ marginRight: "10px" }}
                  />
                  20.7.1 Denying you goods or services.
                </li>
                <li>
                  <AiOutlineCheck
                    color="#ff291c"
                    style={{ marginRight: "10px" }}
                  />
                  20.7.2 Charging you different prices or rates for goods or
                  services, including through granting discounts or other
                  benefits, or imposing penalties.
                </li>
                <li>
                  <AiOutlineCheck
                    color="#ff291c"
                    style={{ marginRight: "10px" }}
                  />
                  20.7.3 Providing you a different level or quality of goods or
                  services.
                </li>
              </ul>
              <p>
                20.7.4 Suggesting that you may receive a different price or rate
                for goods or services or a different level or quality of goods
                or services.
              </p>
              <h1>21. CONTACT US</h1>
              <p>
                General questions, comments and requests regarding this privacy
                notice are welcomed and should be addressed to our compliance
                team using the following email address: legal@FireUp.com. For
                opt-out requests only, please use the following email address:
                datarequest@FireUp.com.
              </p>
              <h1>22. ADDITIONAL PROVISIONS FOR TRANSFER DATA</h1>
              <p>
                ‍22.1 Privacy Shield Framework. As noted above, FireUp, Inc.
                complies with the EU-U.S. Privacy Shield Framework and the
                Swiss-U.S. Privacy Shield Framework as set forth by the U.S.
                Department of Commerce regarding the collection, use, and
                retention of transfer data.
              </p>
              <p>
                22.2 Types of Transfer Data Collected: The types of transfer
                data that may be collected by FireUp, Inc. are the types of
                Personal Data described under Sections 3 and 4 above.
                Additionally, Processed Transfer Data includes information
                relating to social media influencers whose Personal Data is
                entered into FireUp, Inc.'s business customer's Platform
                account by such business customer, excluding that certain
                publicly available influencer data for which FireUp, Inc. is
                the controller and which is subject to FireUp's Influencer
                Privacy Notice.
              </p>
              <p>
                22.3 Purpose for Which Transfer Data is Collected and Used: The
                purposes for which transfer data is collected and used by
                FireUp, Inc. are those purposes described under Sections 5 and
                6 above. FireUp, Inc. collects and uses Processed Transfer Data
                only on the instructions of the applicable third-party
                controller.
              </p>
              <p>
                22.4 Types of Third Parties to which Transfer Data is Disclosed:
                Transfer data is disclosed with the same types of third parties
                and for the same purposes as are described under Section 7
                above.
              </p>
              <p>
                22.5 Access: Your rights to access your transfer data processed
                by us are described in Section 12 above. With respect to
                Processed Transfer Data, FireUp, Inc. will work with the
                applicable third-party controller to facilitate any right of
                access as described in Section 12.
              </p>
              <p>
                22.6 Onward Transfer: Before we disclose any of your transfer
                data to a third party we will require that such third party
                provide the same level of privacy protection as is required by
                the Privacy Shield Principles. FireUp, Inc.’s accountability
                for transfer data that it receives under the Privacy Shield and
                transfers to a third party is outlined in the Privacy Shield
                Principles. In particular, FireUp, Inc. remains liable under
                the Privacy Shield Principles if third-party agents that it
                retains to process transfer data on FireUp, Inc.’s behalf
                process such transfer data in a manner inconsistent with the
                Privacy Shield Principles, unless FireUp, Inc. can prove that
                it is not responsible for the event giving rise to the damage.
              </p>
              <p>
                22.7 Choice: If you no longer wish to have your transfer data
                disclosed to third parties, you may choose to "opt out" by
                notifying us. To do so, send an email to
                datarequest@FireUp.com. Please be aware that your transfer data
                may have been previously disclosed to third parties in
                accordance with the terms of this privacy notice. Further, with
                respect to Processed Transfer Data, FireUp, Inc. will work with
                the applicable third-party controller to facilitate such choice.
                Also, FireUp, Inc. will provide you with notice before using
                your transfer data for a purpose other than that for which it
                was originally collected or subsequently authorized by you, and
                you may choose to “opt out” of such use by following the
                directions provided in the notice. However, even after any
                “opt-out”, your transfer data may be used and disclosed to a
                third party upon a good faith belief that such disclosure is
                required in order to comply with an applicable statute,
                regulation, rule or law, a subpoena, a search warrant, a court
                or regulatory order, lawful requests by public authorities,
                including to meet national security or law enforcement
                requirements, or other valid legal process. Without limitation
                of any other provision contained herein, following your “opt
                out”, your information that already has been gathered may
                continue to be used and to be disclosed to third parties,
                provided that such information will be anonymized in order to
                ensure that you cannot be identified anymore.
              </p>
              <p>
                22.8 Data Integrity and Purpose Limitation: Except as may be
                authorized by you, we use your transfer data in a manner that is
                compatible with and relevant for the purpose for which it was
                collected. To the extent necessary for these purposes, we take
                reasonable steps to ensure that transfer data is accurate,
                complete, current, and reliable for its intended use. With
                respect to Processed Transfer Data, FireUp, Inc.'s obligations
                under this Section 22.8 may be limited to working with the
                applicable third-party controller, given FireUp, Inc.'s role as
                a processor.
              </p>
              <p>
                22.9 Recourse, Enforcement and Liability: FireUp, Inc. uses the
                self-assessment method to verify the attestations and assertions
                made herein and to ensure that its privacy practices have been
                implemented as presented herein. Any questions, complaints, or
                concerns regarding this privacy notice should be directed to
                FireUp, Inc. in accordance with Section 21 above. FireUp, Inc.
                will investigate and attempt to resolve any disputes/complaints,
                including correcting any transfer data, using transfer data
                consistent with the Privacy Shield Principles, reversing or
                correcting the effects of noncompliance, and assuring that
                future processing of transfer data will be in conformity with
                the Privacy Shield Principles. For any complaints regarding
                transfer data that cannot be resolved by FireUp, Inc. directly,
                FireUp, Inc. commits to cooperate with the panel established by
                the EU Data Protection Authorities (“DPAs”) and the Swiss
                Federal Data Protection and Information Commissioner
                (“Commissioner”), as applicable, to investigate unresolved
                disputes/complaints and comply with the advice given by the
                panel and/or Commissioner. With respect to the application of
                Privacy Shield Principles to transfer data, the panel
                established by the DPAs and/or the Commissioner, as applicable,
                is the independent dispute resolution body designed to address
                complaints and provide appropriate recourse to you free of
                charge. As further described in the Privacy Shield Principles, a
                binding arbitration option will be made available to you in
                order to address residual complaints regarding transfer data
                that have not been resolved by other means. See Section C of
                Annex I to the Privacy Shield Principles at
                https://www.privacyshield.gov/article?id=C-Pre-Arbitration-Requirements
                and
                http://trade.gov/td/services/odsi/swiss-us-privacyshield-framework.pdf.
                The Federal Trade Commission has jurisdiction over FireUp,
                Inc.’s compliance with the Privacy Shield. Adherence by FireUp,
                Inc. to the Privacy Shield Principles and the above-set forth
                provisions regarding transfer data may be limited (a) to the
                extent necessary to meet national security, public interest or
                law enforcement requirements; (b) by statute, government
                regulation, or case law that creates conflicting obligations or
                explicit authorizations; or (c) if the effect of the GDPR or
                Member State law is to allow exceptions or derogations, provided
                that such exceptions or derogations are applied in comparable
                contexts.
              </p>
              <p>
                This version of this privacy notice is effective as of, and was
                last updated on, March 21, 2022 (the “Effective Date”).
              </p>
            </div>
          </Col>
        </Row>
      </div>
    </Layout>
  );
}

export default PrivacyPolicy;
