// export const light = {
//   background: "#fbfbfb",
//   primary: "#0B5394",
//   secondary: "#B85B22",
//   iconBg: "rgba(0, 0, 0, 0.062)",
//   sidebar: "#FFFFFF",
//   menuicon: "gray",
//   headerSpan: "#0B5394",
//   secondaryText: "gray",
//   text: "#1C2833",
//   borderLight: "rgb(231, 231, 231)",
//   cardColor: "#FFFFFF",
//   lightCard: "#F8F9F9",
//   cardShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
//   tableHeader: "#F8F9F9",
//   tagsText: "gray",
//   tagsHover: "#f8f9f9",
//   link: "#3699FF",
//   tabhover: "#F8F9F9",
//   tabShadow: "rgba(33, 35, 38, 0.1) 0px 10px 10px -10px",
//   siderbarActive: "rgb(66, 66, 66)",
//   cardHeading: "hsl(234, 12%, 34%)",
//   red: "hsl(0, 78%, 62%)",
//   cyan: "hsl(180, 62%, 55%)",
//   orange: "#EB984E",
//   blue: "hsl(212, 86%, 64%)",
//   green: "#2ECC71",
//   yellow: "#F4D03F",
//   purple: "#A569BD",
// };

// export const dark = {
//   background: "#212528",
//   primary: "#0B5394",
//   secondary: "#B85B22",
//   iconBg: "rgba(255, 255, 255, 0.164)",
//   sidebar: "#282c31",
//   menuicon: "gray",
//   headerSpan: "white",
//   secondaryText: "gray",
//   text: "#c9d1d9",
//   borderLight: "#555",
//   cardColor: "#282c31",
//   lightCard: "#212528",
//   cardShadow: "",
//   tableHeader: "#282c31",
//   tagsText: "rgb(177, 177, 177)",
//   tagsHover: "#aed6f133",
//   link: "#3699FF",
//   tabhover: "#31353b",
//   tabShadow: "rgba(33, 35, 38, 0.1) 0px 10px 10px -10px",
//   siderbarActive: "white",
//   cardHeading: "#c9d1d9",
//   red: "hsl(0, 78%, 62%)",
//   cyan: "hsl(180, 62%, 55%)",
//   orange: "#EB984E",
//   blue: "hsl(212, 86%, 64%)",
//   green: "#2ECC71",
//   yellow: "#F4D03F",
//   purple: "#A569BD",
// };
 
export const COLORS = {
  primaryColor: "#F8B917",
  secondaryColor: "#ef5a29",
  thirdColor: "#fec60aab",
};
