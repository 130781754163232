import React from "react";
import { Typography } from "antd";
// import "./HomeSectionExtraOne.css";

const { Title } = Typography;
const HomeSectionExtraOne = () => {
  return (
    <div className="section-extra-one-container">
      <div className="section-extra-one-wrapper">
        <div className="heading-container">
          <div>How does it work</div>
        </div>
        <div className="section-extra-content-container-reverse">
          <div className="section-extra-content">
            <div>
              <div className="content-l1">STEP 01</div>
              <div className="content-l2">CREATE A CAMPAIGN</div>
              <div className="content-l3">
                Lorem ipsum dolor sit amet consectetur adipiscing elit mattis
                semper, purus vestibulum nam vitae, lacus tempus libero feugiat
                neque natoque quisque fames.
              </div>
              <div className="content-btn">Create Your Campaign</div>
            </div>
          </div>
          <div className="section-extra-img-container">
            <img src="https://influence.1onestrong.com/wp-content/uploads/sites/13/2022/03/jp04-800x465.jpg" />
          </div>
        </div>
        <div className="section-extra-content-container">
          <div className="section-extra-img-container">
            <img src="https://influence.1onestrong.com/wp-content/uploads/sites/13/2022/03/jp05-800x465.png" />
          </div>
          <div className="section-extra-content">
            <div>
              <div className="content-l1">STEP 02</div>
              <div className="content-l2">CHOOSE INFLUENCERS</div>
              <div className="content-l3">
                Lorem ipsum dolor sit amet consectetur adipiscing elit mattis
                semper, purus vestibulum nam vitae, lacus tempus libero feugiat
                neque natoque quisque fames.
              </div>
            </div>
          </div>
        </div>
        <div className="section-extra-content-container-reverse">
          <div className="section-extra-content">
            <div>
              <div className="content-l1">STEP 03</div>
              <div className="content-l2">MONITOR YOUR CAMPAIGN</div>
              <div className="content-l3">
                Lorem ipsum dolor sit amet consectetur adipiscing elit mattis
                semper, purus vestibulum nam vitae, lacus tempus libero feugiat
                neque natoque quisque fames.
              </div>
            </div>
          </div>
          <div className="section-extra-img-container">
            <img src="https://influence.1onestrong.com/wp-content/uploads/sites/13/2022/03/jp06-800x465.png" />
          </div>
        </div>
        <div className="section-extra-content-container">
          <div className="section-extra-img-container">
            <img src="https://influence.1onestrong.com/wp-content/uploads/sites/13/2022/03/jp07-800x532.png" />
          </div>
          <div className="section-extra-content">
            <div>
              <div className="content-l1">STEP 04</div>
              <div className="content-l2">CHECK YOUR REPORT CALMLY</div>
              <div className="content-l3">
                Lorem ipsum dolor sit amet consectetur adipiscing elit mattis
                semper, purus vestibulum nam vitae, lacus tempus libero feugiat
                neque natoque quisque fames.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeSectionExtraOne;
