import React, { useState } from "react";
import { Button, Drawer, Typography } from "antd";
import { GiHamburgerMenu } from "react-icons/gi";
import { RiArrowDownSFill } from "react-icons/ri";
import { BsArrowRight } from "react-icons/bs";
import { HiDocumentText } from "react-icons/hi";
import { FaMicrophoneAlt, FaTicketAlt } from "react-icons/fa";
import { MdBook } from "react-icons/md";
import FireLogo from "../../../assets/FireUp-removebg.png";
import FireLogoBlack from "../../../assets/FireUp-black-removebg.png";
import "./NavDrawer.css";

const { Text } = Typography;

const NavDrawer = ({
  visible,
  childrenDrawer,
  childrenDrawer2,
  showDrawer,
  onClose,
  showChildrenDrawer,
  onChildrenDrawerClose,
  showChildrenDrawer2,
  onChildrenDrawerClose2,
}) => {
  return (
    <div className="navDrawer-container">
      <div className="navDrawer-img-container">
        <img style={{ width: "100%", height: "100%" }} src={FireLogoBlack} />
      </div>
      <div className="ham-container">
        <GiHamburgerMenu
          onClick={showDrawer}
          style={{ fontSize: "24px" }}
        />
      </div>
      <Drawer
        title={
          <div style={{ width: "7rem", height: "100%" }}>
            <img
              style={{ width: "100%", height: "100%" }}
              src={FireLogoBlack}
            />
          </div>
        }
        width={520}
        closable={true}
        onClose={onClose}
        visible={visible}
      >
        <div className="menu">
          <ul>
            <li>Product</li>
            <li className="btn" onClick={showChildrenDrawer2}>
              Solutions
              <BsArrowRight style={{ fontSize: "25px", fontWeight: "700" }} />
            </li>
            <li>Customers</li>
            <li className="btn" onClick={showChildrenDrawer}>
              Learn
              <BsArrowRight style={{ fontSize: "25px", fontWeight: "700" }} />
            </li>
            {/* <li>Beauty leaderboard</li> */}
            <li>Talk to us</li>
          </ul>
        </div>
        <Drawer
          title="learn"
          width={320}
          closable={true}
          onClose={onChildrenDrawerClose}
          visible={childrenDrawer}
        >
          <div className="learn-hover-class">
            <div style={{ borderBottom: "0.005em solid #c2d7e6" }}>
              <FaMicrophoneAlt style={{ color: "#456682", fontSize: "25px" }} />
              <Text
                style={{ color: "#456682", fontWeight: 500, fontSize: "18px" }}
              >
                Podcast
              </Text>
              <Text style={{ fontSize: "17px" }}>
                Listen to The Fast Traack to accelerate your influencer
                marketing knowledge.
              </Text>
            </div>
            <div style={{ borderBottom: "0.005em solid #c2d7e6" }}>
              <MdBook style={{ color: "#456682", fontSize: "25px" }} />
              <Text
                style={{ color: "#456682", fontWeight: 500, fontSize: "18px" }}
              >
                Resources
              </Text>
              <Text style={{ fontSize: "17px" }}>
                Case studies, insight reports and playbooks on successful
                influencer strategies.
              </Text>
            </div>
            <div style={{ borderBottom: "0.005em solid #c2d7e6" }}>
              <HiDocumentText style={{ color: "#456682", fontSize: "25px" }} />
              <Text
                style={{ color: "#456682", fontWeight: 500, fontSize: "18px" }}
              >
                Blog
              </Text>
              <Text style={{ fontSize: "17px" }}>
                Read the latest product news, trend analysis and editorial rifts
                on the blog.
              </Text>
            </div>
            <div style={{ borderBottom: "0.005em solid #c2d7e6" }}>
              <FaTicketAlt style={{ color: "#456682", fontSize: "20px" }} />
              <Text
                style={{ color: "#456682", fontWeight: 500, fontSize: "18px" }}
              >
                Events
              </Text>
              <Text style={{ fontSize: "17px" }}>
                Come join us for exclusive events with industry experts!
              </Text>
            </div>
          </div>
        </Drawer>
        <Drawer
          title="Solutions"
          width={320}
          closable={true}
          onClose={onChildrenDrawerClose2}
          visible={childrenDrawer2}
        >
          <div className="product-hover-class">
            <div
              className="drop-hover-class sec-1"
              style={{ borderBottom: "0.005em solid #c2d7e6" }}
            >
              <Text className="drop-sec-title">TEAMS</Text>
              <ul>
                <li>For Brands</li>
                <li>For Ecommerce</li>
                <li>For CMOs</li>
                <li>For Agencies</li>
              </ul>
            </div>
            <div className="drop-hover-class sec-2">
              <Text className="drop-sec-title">USE CASES</Text>
              <div className="list-container">
                <ul>
                  <li>Organic Campaigns</li>
                  <li>Paid Campaigns</li>
                  <li>Affiliate Programs and Social Commerce</li>
                  <li>Influencer Discovery and Vetting</li>
                  <li>Coordination and Visibility</li>
                </ul>
                <ul>
                  <li>Organic Campaigns</li>
                  <li>Paid Campaigns</li>
                  <li>Affiliate Programs and Social Commerce</li>
                  <li>Influencer Discovery and Vetting</li>
                  <li>Coordination and Visibility</li>
                </ul>
              </div>
            </div>
          </div>
        </Drawer>
      </Drawer>
    </div>
  );
};

export default NavDrawer;
